import React, { useState, useEffect } from "react"
// import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie"
import styled from "styled-components"

import GlobalFonts from "../font/fonts"

import Popup from "react-popup"

import Header from "../components/header"
import NavV3 from "../components/NavV3"
import NavV2 from "../components/NavV2"

import SimpleSlider from "../components/SimpleSlider"

// CarouselPage
import ProForma from "../components/ProForma"
import Deck from "../components/Deck"
import FooterContainer from "../components/FooterContainer"
import Signup from "../components/Signup"
import ExplanationVideo from "../components/ExplanationVideo"

import AlphaExplanationVideo from "../components/AlphaExplanationVideo"
import AlphaSteps from "../components/AlphaSteps"

import gray_logo_text from "../images/cloudCastleLogo_dark_gray.png"
import main_landing_page_qr from "../images/main_landing_page_qr.png"
import EbookCloudCastlesCover from "../images/EbookCloudCastlesCover.png"

import topbackground from "../assets/TopPolygon1.svg"
import topbackground2 from "../assets/TopPolygon2.svg"

import phones from "../assets/Phones2.svg"
import shadowPhones from "../assets/shadowPhones.svg"
import white_logo_text from "../images/white_logo_text.png"

import path1 from "../assets/Arrow1.svg"
import path2 from "../assets/Arrow2.svg"
import path3 from "../assets/Arrow3.svg"

import icon1 from "../assets/ICON.svg"
import icon2 from "../assets/ICON2.svg"
import icon3 from "../assets/ICON3.svg"
import icon4 from "../assets/ICON4.svg"

import vectorBackground from "../assets/VectorBackground.svg"
import backgroundSlide from "../assets/BackgroundSlide.svg"
import wave from "../assets/Wave.svg"
import waveTop from "../assets/WaveTop.svg"

import Fab from "@mui/material/Fab"
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import "bootstrap/dist/css/bootstrap.min.css"

import Helmet from "react-helmet"

import USAMap from "react-usa-map"
import Faq from "react-faq-component"

import "../styles/faq.css";

const data = {
  title: "Cloud Castles FAQ (How it works)",
  rows: [
        
    {
    "title" : "How It Works",
    "content":"Step 1 - Choose Your Castle "+"<br>"+ "Step 2 - Make An Offer"+"<br>"+"Step 3 - Close"+"<br>"+"Step 4 - Get Paid"+"<br>"+"Step 5 - Share and Build Wealth",
    },
    {
    "title" : "How quickly can I expect a return on investment?",
    "content":"We can’t guarantee when you will receive a return, but the goal is to generate revenue as soon as we list the Cloud Castle on sites like Airbnb.  In whatever month the property profits, we will wait until the end of the month to calculate each individual investor’s payout.",
    },
    {
    "title" : "How long before I recoup my initial investment?",
    "content":"Our goal is between 3-5 years. We look for properties that can secure at least a 20% cash on cash return (annual profit / initial investment). For reference, the industry standard is 15%.",
    },
    {
    "title" : "Will there be a legally binding contract for my investment?",
    "content":"Yes, we will execute an LLC operating agreement for each property that specifies the terms of your ownership interest and payout.",
    },
    {
    "title" : "How will you accept payment and returns for my investment?",
    "content":"Payments and payouts will both be handled in our app. You will be able to connect to your bank to process payments and payouts.",
    },
    {
    "title" : "Where will the properties be listed?",
    "content":"You’ll be able to see all relevant info about each property in our app.",
    },
    {
    "title" : "How are you going to set Airbnb or Homeaway pricing, will it be variable or fixed?",
    "content": "Pricing will vary by season and popular events. At the beginning we will use a third-party service called Beyond Pricing to determine pricing until we develop our own proprietary technology.",
    },
    {
    "title" : "Will there be earning reports for the specific property?",
    "content": "Yes, you will be able to see your earnings for a specific property in the CLOSED! tab in the app.",
    },
    {
    "title" : "How will potential losses and liabilities be handled?",
    "content": "They'll be handled between the Acquisition Costs and CapEx reserves. If unprofitability outlasts cash flows, then we will sell the property to recoup costs for investors.",
    },
    {
    "title" : "What happens to my investment if a property doesn’t work out in the early or latter stages of Cloud Castles?",
    "content": "If a property is not profitable, Cloud Castles will list it on the market to recoup costs. We will return initial investments plus any profit to investors in accordance with their ownership interest.",
    },
    {
    "title" : "Is there a cap on investors?",
    "content": "There is no cap on investors, but there will always be a cap on the number of shares available at 8,000.",
    },
    {
    "title" : " How much capital are you looking to raise?",
    "content":"The capital raise for each property will be based on the purchase price, closing costs, improvement costs, and legal costs. These costs will be reflected in the share price of the Cloud Castle.",
    },
    {
    "title" : " Will my invest be diluted, if so how much can I expect?",
    "content": "No, but we usually provide a discounted share price the earlier you invest. So if you wait too long, you may own less of a Cloud Castle and earn less for the exact same investment amount!",
    },
    {
    "title" : "Will my investment be spread over multiple properties, or will I only have ownership in a singular property?",
    "content": "Your investment will be based on one property at a time. You will get to choose your investment amount, and have the option to invest in multiple properties.",
    },
    {
    "title" : " What will be the main form of communication for investors?",
    "content": "The main form of communication between Cloud Castles and investors will be in-app and email.",
    },
    {
    "title" : " Can I pull my investment at anytime and are there fees to do so?",
    "content": "If you decide to sell your ownership interest in a property, Cloud Castles will facilitate a buyer for a small fee. If no buyers are interested, then Cloud Castles has the option, but not the obligation, to buy your ownership interest from you.",
    },
    {
    "title" : " Can I pull out a portion of my investment?",
    "content" :  "Yes, the same rules as above applies.",
    },
    {
    "title" : " Does Cloud Castles guarantee liquidity?",
    "content": "We can't guarantee liquidity, but between other investors, Cloud Castles, or a sale of the property, there's a high likelihood for liquidity.",
    },
    {
    "title" : " Do I have the opportunity to invest in Cloud Castles itself?",
    "content": "As of now, your investments are limited to properties on Cloud Castles’s platform.",
    },
    {
    "title" : " What happens to my investment in properties if Cloud Castles is acquired?",
    "content":  "Your ownership interest in a property will still be intact based on the LLC created for that property. Payouts will be handled by Cloud Castles until that responsibility is transitioned to the acquirer.",
    },
    {
    "title" : " Will there be an incentive for referring someone to Cloud Castles?",
    "content":"Yes, we will incentivize users to refer investors through free nights, discounted shares, or free shares.",
    },
    {
    "title" : " How often will I receive returns?",
    "content":  "Each month Cloud Castles will calculate returns and pay out investors in-app.",
    },
    {
    "title" : " Are there any hidden fees?",
    "content": "There will be no hidden fees. You will know exactly all revenue and costs involved in your property.",
    },
    {
    "title" : " How does equity work?",
    "content": "Your equity will be equal to the amount of shares you invest in a property. So if you buy 100 shares, your equity will be 1%. You receive monthly profit distributions based on your equity, and the same goes if we ever sell the property. ",
    },
    {
    "title" : " How do you price equity shares?",
    "content": "We price equity based on acquisition costs, renovation costs, reserves for rainy days, and a markup since we’re holding all the debt.  Prices change based on demand and timing."
    },
  ],
  styles: {
    // bgColor: '',
    titleTextColor: "white",
    // titleTextSize: '48px',
    rowTitleColor: "white",
    // rowTitleTextSize: 'medium',
    rowContentColor: '',
    rowContentTextSize: "16px",
    // rowContentPaddingTop: '10px',
    rowContentPaddingBottom: "10px",
    rowContentPaddingLeft: "50px",
    // rowContentPaddingRight: '150px',
    // arrowColor: "red",
    transitionDuration: "1s",
    timingFunc: "ease"
  },
}

const styles = {
  bgColor: '#8bbef3',
  titleTextColor: "white",
  rowTitleColor: "white",
  rowContentColor: 'white',
  // arrowColor: "red",
}

const config = {
  animate: true,
  // arrowIcon: "V",
  tabFocus: true
}

const Alpha = () => {
  const handleSumit = event => {}

  // let navigate = useNavigate();
  const routeChange = () => {
    let path = `https://cloudcastles.onelink.me/GiaO/2187bebc`
    console.log(path)
    window.location.href = path
    // navigate(path);
  }

  useEffect(() => {}, [])

  return (
    <Body>
      <Helmet>
        <html lang="en" />
        <title>Cloud Castles FAQ</title>
        <meta
          name="google-site-verification"
          content="lRDxE-lZUVoBRi5yV2AHoVkcaALQME9xGG-XwQYIbcU"
        />{" "}
        <meta
          name="keywords"
          content="Vacation home Real estate investing, is real estate investing worth it, is real estate investing a business,how to start real estate investing"
        />
        <meta
          name="description"
          content="Allowing Anyone To Own Anywhere. Your go to Vacation home Real estate investing company."
        />
      </Helmet>

      <Hero>
        <GlobalFonts />

        <Faq data={data} styles={styles} config={config} />

      </Hero>
    </Body>
  )
}

export default Alpha

const style = {
  margin: 0,
  top: "auto",
  right: 20,
  bottom: 20,
  height: 150,
  width: 150,
  zIndex: 100,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  left: "auto",
  position: "fixed",
}

const Body = styled.div`
  position: relative;
   display: flex;
   background-color:#8bbef3;

   display: block;
  overflow: auto;

`

const Tbackground = styled.div`
  max-width: 100%;
  overflow-x: hidden;
`

const Hero = styled.div`
  max-width: 100%;
  overflow-x: hidden;

  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  margin: 100px;
  height:1000px;
  background-color:#8bbef3;

  @media only screen and (max-width: 625px) and (min-width: 100px) {
    margin: 100px 10px auto 10px;    
  }
`

const Logo = styled.img`
  max-width: 40%;

  /*padding: 0px 0px 0px 30px;*/

  margin-right: 30px;
  position: relative;
  @media only screen and (max-width: 40em) {
  }
`

const Logo2 = styled.img`
  /*max-width: 40%;*/

  /*padding: 0px 0px 0px 30px;*/
  height: 30px;
  /*margin: 4px auto;*/
  /*margin-right: 30px;*/
  position: relative;
  @media only screen and (max-width: 40em) {
  }
`

const Logo3 = styled.img`
  /*max-width: 40%;*/
  height: 50px;
  /*padding: 0px 0px 0px 30px;*/

  margin: 4px auto;
  position: relative;
  @media only screen and (max-width: 40em) {
  }
`

const Hero1 = styled.div`
  
  display: flex;
margin: 100px auto 0px 0px;
padding 100px auto auto 100px;
  justify-content: center;
  align-content: center;
  margin: auto;

  @media only screen and (max-width: 625px) and (min-width: 100px) {

    margin: 100px auto 50px 100px;

  }  

  
`

const Hero2 = styled.div`
  
  
  margin:  auto;
  padding 100px 0px 0px 100px;
    flex: 1;
  align-items:space-evenly;
  justify-content: center;
`

const CTADiv = styled.div``

const TopBackground = styled.img`
  position: absolute;
  left: -14.63%;
  right: 9.75%;
  top: -4.95%;
  bottom: 78.14%;
  z-index: -10;

  background: #8bbef3;
  border-radius: 333px;
  transform: matrix(0.98, -0.24, 0.2, 0.97, 0, 0);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
    width: 1712.46px;
    height: 1556.42px;
    left: -717.75px;
    top: -656.2px;

    background: #8bbef3;
    border-radius: 333px;
    transform: matrix(0.98, -0.24, 0.2, 0.97, 0, 0);
  }
`

const TopBackground1 = styled.img`
  position: absolute;
  left: -14.63%;
  right: 9.75%;
  top: -40.95%;
  bottom: 78.14%;

  background: #8bbef3;
  border-radius: 333px;
  transform: matrix(0.98, -0.24, 0.2, 0.97, 0, 0);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`

const TopBackground0 = styled.img`
  overflow-x: hidden;
  position: absolute;
  left: -14.63%;
  right: 0.75%;
  top: -0.95%;
  bottom: 8.14%;

  opacity: 0.1;

  background: #8bbef3;
  border-radius: 333px;
  transform: matrix(0.9, -0.24, 0.2, 0.97, 0, 0);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`

const TopBackground2 = styled.img`
  overflow-x: hidden;
  position: absolute;
  left: -1.72%;
  right: 32.8%;
  top: -16.64%;
  bottom: 81.18%;

  background: #8bbef3;
  opacity: 0.05;
  border-radius: 333px;
  transform: matrix(1, -0.1, 0.08, 1, 0, 0);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`
const Phones = styled.img`
  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);

  margin: auto;
  max-width: 80%;

  @media only screen and (max-width: 625px) and (min-width: 100px) {
    /*margin: 10px 10px 100px 100px;*/
  }
`
const Shadowphone = styled.img`
  position: absolute;
  width: 829.14px;
  height: 366.75px;
  right: 100.75px;
  top: 400.63px;

  margin: 150px auto 0px auto;
  @media only screen and (max-width: 625px) and (min-width: 100px) {
    margin: 200px -300px 0px 100px;
  }
`

const H1Text = styled.h1`
  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  font-size: 35.8802px;
  line-height: 119%;
  /* or 43px */

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-duration: 3s;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  font-feature-settings: "liga" off;

  margin: 30px auto;
  padding: auto;

  color: #ffffff;
  @media only screen and (max-width: 600px) and (min-width: 100px) {
  }
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`

const PText = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16.7436px;
  line-height: 151%;
  /* or 25px */
  margin: 30px auto;
  color: #fafdff;
  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);

  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`

const PText2 = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 10.7436px;
  line-height: 151%;
  /* or 25px */
  /*margin: 30px auto;*/
  color: #1d293f;
  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);

  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`

const PText3 = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 12.7436px;
  line-height: 151%;
  /* or 25px */
  /*margin: 30px auto;*/
  color: #1d293f;
  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);

  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`

const CTAButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  padding-left: 10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16.1461px;
  line-height: 24px;

  color: #ffffff;

  background: #8578fc;
  box-shadow: 0px 3.58802px 22.4251px rgba(102, 75, 218, 0.26);
  border-radius: 40.3653px;

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`

const CTAForm = styled.form`
  margin: auto;
  flex-direction: row;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
`

const CTAInput = styled.input`
  display: flex;
  padding: 16.1461px 30.7772px;

  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16.1461px;
  line-height: 24px;

  color: black;

  border: 0.897006px solid #8578fc;
  box-sizing: border-box;
  filter: drop-shadow(0px 3.58802px 22.4251px rgba(102, 75, 218, 0.26));
  border-radius: 40.3653px;
  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);

  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`

/*
const Wave = styled.img`
  position: absolute;
  width: 100%;
  z-index: 0;
    top: 3139.21px;
`

const WaveTop = styled.img`
  position: absolute;
  width: 100%;
  opacity:80%;
  z-index: 0;
    top: 3139.21px;
`
*/

const FooterDiv = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
  left: 0px;
  top: 0.21px;
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`

const SignupDiv = styled.div`
  /*position: relative;*/

  width: 100%;
  height: 300px;
  z-index: 2;
  top: 55.21px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`
